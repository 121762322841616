import { SearchIcon } from "@components/search/ComponentIcon";
import BaseLayout from "@layout/base-layout";
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Modal,
  Select,
} from "antd";
import _ from "lodash";
import moment from "moment";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
import { RightArrow } from "@components/icons/icons";
import mainController from "@controller/mainController";
import request from "@libs/request/request";
import deLocale from "antd/lib/locale-provider/de_DE";
import locale from "antd/lib/locale-provider/en_GB";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import TailwindToggle from "../src/components/search/TailwindToggle";
import useWindowSize from "src/hooks/useWindowSize";

const { Option } = Select;
const { RangePicker } = DatePicker;
const controller = new mainController();

const Index = () => {
  const { t, i18n } = useTranslation("common");
  const router = useRouter();

  const [sports, setSports] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState("");
  const [hardSport, setHardSport] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sportsSearch, setSportsSearch] = useState([]);
  const [cat, setCat] = useState("{}");
  const [sp, setSp] = useState("");
  const [date, setDate] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [months, setMonths] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDate, setShowDate] = useState([]);
  const [showBtnDate, setShowBtnDate] = useState(false);
  const [dateShow, setDateShow] = useState(false);
  const [monthDate, setmonthDate] = useState("");
  const [showMonth, setShowMonth] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 640;
  const isTablet = width < 640 && width < 1024;

  useEffect(() => {
    _getdata();
  }, [countriesSearch, sportsSearch]);

  useEffect(() => {
    setShowBtnDate(true);
  }, [showDate, endDate]);

  const _getdata = () => {
    request
      .get("/offer/countries")
      .then((result) => {
        setCountries(result?.data?.countries);
        setHardSport([
          { title: t("frontpage.i_am_open") },
          ...result?.data?.sports,
        ]);
        setSports([
          { title: t("frontpage.i_am_open") },
          ...result?.data?.sports,
        ]);
      })
      .catch((error) => console.log(error.response));
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const _changeData = (k, v) => {
    switch (k) {
      case "category":
        setCat(v);
        break;
      case "country":
        setSp(v);
        break;
      case "date":
        setDate(v);
        break;
    }
  };
  const _Goto = () => {
    let query = "";
    let path = "";

    if (date != undefined && date != "") {
      query = "?date=" + date;
    }
    if (cat != undefined && cat != "") {
      path += "/" + cat;
    }
    if (sp != undefined && sp != "") {
      path += "/" + sp;
    }

    router.push("/s/moverii" + path + query);
  };

  const onChange4 = (value) => {
    if (value.length) {
      const month = value.map((d) => moment(`2020/${d}/1`).format("MMMM"));
      setMonths(month);
    }
    setValue(value);
    setmonthDate(value);
    _changeData("date", value);
  };

  return (
    <div className="container">
      <div className="w-full h-full flex flex-col md:flex-row items-center ">
        <div className="w-full h-full">
          <img src="/static/images/404.jpg" alt="" className="mx-auto" />
        </div>

        <div className=" flex flex-col gap-4 items-start  justify-center w-full py-16 px-5 text-center">
          <div className="flex flex-col gap-5 items-start">
            <p className="text-lg text-left">{t("notFound404.sorry")}</p>
          </div>
          <div className="md:w-3/4 w-full flex flex-col gap-5 md:gap-6 lg:gap-3 mt-2 md:text-base font-medium border border-orange rounded-2xl px-5 py-5">
            <div className="flex flex-col items-start">
              <h1 className="m-0 -mb-2 flex items-center justify-center gap-x-2 text-base font-medium">
                {t("notFound404.sport")}
              </h1>
              <Select
                placeholder={t("notFound404.experience")}
                allowClear
                name={"category"}
                className="w-full not-found-style"
                bordered={false}
                dropdownClassName="dropDownSearch"
                onChange={(e) => {
                  _changeData("category", e);
                }}
              >
                <Select.OptGroup label="Popular categories">
                  {sports?.map((i, index) => {
                    return (
                      <Option value={i.alias} key={i.key}>
                        <span className="flex gap-1">{i.title}</span>
                      </Option>
                    );
                  })}
                </Select.OptGroup>
              </Select>
            </div>
            <div className="flex flex-col items-start">
              <h1 className="m-0 -mb-2 flex items-center justify-center gap-x-2 text-base font-medium">
                {t("notFound404.holiday")}
              </h1>
              <Select
                placeholder={t("notFound404.where_travel")}
                allowClear
                className="select-dist-antd w-full border-0 pt-0 not-found-style"
                bordered={false}
                openOnFocus
                name={"country"}
                dropdownClassName="dropDownSearch"
                showAction={["focus", "click"]}
                onChange={(e) => {
                  _changeData("country", e);
                }}
              >
                <Select.OptGroup
                  label="Popular destinations"
                  className="!max-h-1"
                >
                  {countries?.map((i, index) => {
                    return (
                      <Option key={index} value={i?.alias}>
                        <span className="flex items-center gap-1 ">
                          {i.title}
                        </span>
                      </Option>
                    );
                  })}
                </Select.OptGroup>
              </Select>
            </div>
            <div
              className="flex flex-col items-start cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <h1 className="m-0 flex items-center justify-center gap-x-2 text-base font-medium">
                {t("notFound404.period")}
              </h1>
              <p className="text-[#bfc5d7] cursor-pointer text-sm">
                {dateShow ? (
                  <>
                    <span>
                      {startDate} {endDate}
                    </span>
                    <span> {monthDate}</span>
                  </>
                ) : (
                  t("notFound404.when_travel")
                )}
              </p>
            </div>
            <Button
              onClick={_Goto}
              className="flex gap-2  bg-orange border-orange hover:bg-orange rounded-3xl py-2 h-auto font-bold text-white text-lg hover:text-white md:w-30 w-full justify-center "
            >
              <SearchIcon />
              {t("notFound404.search")}
            </Button>
          </div>
          <div className="flex flex-col gap-4 items-start justify-center w-full pt-8 text-center md:pr-20">
            <p className="text-lg text-left">{t("notFound404.inspired")}</p>
            <Link href={"/"}>
              <a href="/" className="w-full">
                <Button
                  type={"text"}
                  className="w-full justify-center text-lg flex items-center gap-2 bg-[#12A3BA] hover:bg-[#12A3BA] rounded-3xl  px-2 !py-[15px] h-auto font-bold text-white hover:text-white"
                >
                  {t("notFound404.get_inspired")}
                  <RightArrow color="#fff" />
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </div>

      <Modal
        open={isOpen}
        footer={false}
        centered
        closable={false}
        width={isMobile ? "100vw" : isTablet ? "90%" : "60%"}
        className={"w-full scale-105 md:scale-100"}
      >
        <div className="flex flex-col min-h-[100vh] md:min-h-full">
          <div className="flex items-center justify-between">
            <p className="text-[18px] font-semibold">
              {t("notFound404.when_travel")}
            </p>
            <span
              className={`cursor-pointer ${!toggle && "hidden"}`}
              onClick={() => setIsOpen(false)}
            >
              <IoIosClose className="text-3xl text-gray-400 hover:text-black transition-all duration-300" />
            </span>
          </div>

          <div className="flex justify-center mt-4">
            <TailwindToggle setValue={setToggle} value={toggle} />
          </div>

          {toggle ? (
            <div className="w-full mb-5">
              <div onClick={(e) => e.stopPropagation()} className={"mt-8"}>
                <Checkbox.Group
                  onChange={onChange4}
                  value={value}
                  optionType="button"
                  buttonStyle="solid"
                  className="grid grid-cols-2 md:grid-cols-6 gap-3 checked"
                >
                  {_.range(0, 12).map((m) => {
                    return (
                      <Checkbox
                        className={
                          "!h-full !w-full !items-center !justify-center border border-black rounded-xl check !justify-items-center py-3 !ml-0"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        value={moment().add(m, "M").format("YYYY-MM")}
                      >
                        <div
                          className={
                            "flex text-center justify-center items-center text-sm font-semibold"
                          }
                        >
                          {moment().add(m, "M").format("MMMM")}
                        </div>
                        <div
                          className={
                            "flex font-light text-center justify-center items-center text-xs"
                          }
                        >
                          {moment().add(m, "M").format("YYYY")}
                        </div>
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </div>
              {showBtnDate && (
                <div className="flex justify-center mt-6">
                  <button
                    className="bg-orange w-full md:w-[50%] py-2 rounded-full text-white font-medium text-base"
                    onClick={(e) => {
                      setToggle(true);
                      setIsOpen(false);
                      setDateShow(true);
                    }}
                  >
                    {t("voucher.popup_button_confirm")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div
                onClick={(e) => e.stopPropagation()}
                className={`flex w-full relative items-center z-50 justify-center main-filter-date-picker h-80`}
              >
                <ConfigProvider
                  locale={i18n.language === "de" ? deLocale : locale}
                >
                  <RangePicker
                    open={isOpen}
                    size="large"
                    value={showDate}
                    style={{ width: "0", visibility: "hidden" }}
                    disabledDate={disabledDate}
                    dropdownClassName={`!left-[18%]  sm:!left-1/4 md:ml-14 borderLess !border-none ${
                      !isOpen && "hidden"
                    }`}
                    onChange={(e) => {
                      setEndDate(moment(e[0]._d).format("YYYY/MM/DD"));
                      setStartDate(moment(e[1]._d).format("YYYY/MM/DD"));
                      setShowDate(e);
                    }}
                    bordered={false}
                    className={`absolute -bottom-[120px] sm:-bottom-12 !border-none`}
                  />
                </ConfigProvider>
              </div>
              {showBtnDate && startDate != "" && endDate != "" && (
                <div className="flex justify-center mt-6">
                  <p
                    className="py-2 rounded-full text-black font-medium text-base text-center"
                    onClick={() => {
                      setToggle(true);
                      setIsOpen(false);
                      setDateShow(true);
                    }}
                  >
                    {startDate} - {endDate}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

Index.Layout = BaseLayout;

export default Index;
